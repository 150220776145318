import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { VerifyAutoLoginToken } from 'actions/UserAction';
import { setUserAccessToken } from 'helpers/storage';
import { getWorkspaceDetails } from 'selectors/AppSelector';
import { RootState } from 'types/state';

type Props = ReduxProps;

const AutoLogin: React.FC<Props> = (props) => {
	const [searchParams] = useSearchParams();
	const { boardName, workspaceName } = useParams();
	const doAutoLogin = async () => {
		const token = searchParams.get('token');
		if (!token) return;

		const { data, error } = await props.verifyAutoLoginToken({
			token,
			name: workspaceName ?? '',
			host: window.location.host,
			boardUniqueName: boardName ?? '',
		});

		if (error) return;

		try {
			const { token: accessToken, url } = data.result;
			setUserAccessToken(accessToken);
			// TODO: Update user info
			const redirectedUrl = new URL(url);
			window.location.href = redirectedUrl.toString();
		} catch (err) {
			window.location.href = '/';
		}
	};

	useEffect(() => {
		doAutoLogin();
	}, [workspaceName]);

	return null;
};

const mapStateToProps = (state: RootState) => ({
	workspaceDetails: getWorkspaceDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	verifyAutoLoginToken: (body: {
		host: string;
		name: string;
		token: string;
		boardUniqueName: string;
	}) => dispatch(VerifyAutoLoginToken.request(body)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AutoLogin);
