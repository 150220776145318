import styled from '@emotion/styled';
import * as Yup from 'yup';

import Form from 'components/Form';
import ZedaEditor from 'components/ZedaEditor';
import { Colors, FontSize } from 'theme';
import { CustomFieldFormType, CustomFieldType } from 'types/feedbacks';

export interface FormikValueType {
	title: string;
	summary: string;
	type: string;
	customFields: CustomFieldFormType[];
	userName?: string;
	email?: string;
}

const validationSchema = {
	title: Yup.string().required('Title is required'),
	type: Yup.string().required('Type is required'),
	customFields: Yup.array().of(
		Yup.object().shape({
			value: Yup.mixed().when(['isRequired', 'type'], {
				is: (isRequired: boolean) => isRequired,
				then: Yup.mixed().when('type', {
					is: CustomFieldType.MULTI_SELECT,
					then: Yup.array().min(1, 'At least one option should be added.'),
					otherwise: Yup.string().trim().required('Value is required'),
				}),
			}),
		})
	),
};

export const formikValidationSchema = Yup.object().shape({ ...validationSchema });

export const formikValidationSchemaWithNameEmail = Yup.object().shape({
	...validationSchema,
	userName: Yup.string().required('Name is required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
});

export const FormContainer = styled(Form)`
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: 24px;
`;

export const StyledZedaEditor = styled(ZedaEditor)<{ isDescriptionValid: boolean }>`
	* {
		font-size: ${FontSize.text_sm};
		color: ${Colors.grey500};
	}
	height: 120px;
	overflow-y: auto;
	border: ${({ isDescriptionValid }) =>
		`1px solid ${isDescriptionValid ? Colors.grey300 : Colors.error600}`};
	margin-top: 4px;
	border-radius: 8px;
	padding: 10px 14px;
	background-color: ${Colors.white};
	p {
		font-size: ${FontSize.text_sm};
		font-weight: 400;
		margin: 0px;
		color: ${Colors.grey900};
		::before {
			color: ${Colors.grey500} !important;
		}
	}
	:focus,
	:focus-within {
		border: ${({ theme }) => `1px solid ${theme.colors.primary600}`};
	}
`;
